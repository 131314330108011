module.exports = {
  buttonSave: 'Save',
  buttonCancel: 'Back',
  buttonYes: 'Yes',
  buttonInput: 'Submit',
  buttonConfirm: 'Confirm',
  buttonAdd: 'Add',
  buttonBack: 'Back',
  buttonFilter: 'Filter',
  buttonVoid: 'Void',
  buttonDelete: 'Delete',
  buttonOk: 'Ok',
  paymentMethod: 'Payment Method',
  paymentMethodCash: 'Cash',
  paymentMethodCard: 'Card Payment',
  paymentMethodGiftcard: 'Gift Card',
  paymentDirectionIn: 'Pay In',
  paymentDirectionOut: 'Pay Out',
  kindService: 'Service',
  kindProduct: 'Product',
  kindGiftcard: 'Gift Card',
  columnTitleGoods: 'Item',
  columnTitleUnitPrice: 'Unit Price',
  columnTitleQuantity: 'Quantity',
  columnTitleDiscount: 'Discount',
  columnTitleRowSum: 'Sub Total',
  columnTitleBalance: 'Balance',
  discount: 'Discount',
  discountPercentage: 'Discount Percentage',
  discountAmount: 'Discount Amount',
  amount: 'Amount',
  operation: 'Operation',
  createdBy: 'Employee',
  tip: 'Tip',
  category: 'Category',
  comment: 'Comment',
  title: 'Title',
  details: 'Details',
  email: 'Email',
  mobile: 'Phone',
  date: 'Date',
  inputTime: 'Time',
  quantity: 'Quantity',
  minute: 'minutes',
  minutes: 'minutes',
  hour: 'hour',
  hours: 'hours',
  day: 'day',
  days: 'days',
  week: 'week',
  weeks: 'weeks',
  inMinutes: 'minutes',
  inHour: 'hour(s)',
  total: 'Total',
  net: 'Net',
  gross: 'Gross',
  netTotal: 'Net total',
  grossTotal: 'Gross total',
  mainAvg: 'Average Employee',
  search: 'Search',
  shop: 'Shop',
  price: 'Price',
  order: 'Order',
  edit: 'Edit',
  name: 'Name',
  description: 'Description',
  barcode: 'Barcode',
  settings: 'Settings',
  download: 'Download',
  daysAgo: 'day(s) ago',
  daily: 'Daily',
  monthly: 'Monthly',
  type: 'Type',
  confirmDeleteTitle: 'Confirm deletion',
  confirmDeleteConfirmService: 'Are you sure you want to delete this service?',
  confirmDeleteConfirmProduct: 'Are you sure you want to delete this product?',
  confirmDeleteConfirmEmployee:
    'Are you sure you want to delete this employee?',
  confirmDeleteConfirmSalaryRule:
    'Are you sure you want to delete this rule assignment?',
  confirmDeleteConfirmDeduction:
    'Are you sure you want to delete the selected deduction?',
  prettyDateFormat: 'H:mm D. MMMM YYYY.',
  loginButton: 'LOGIN',
  signUp: 'START FREE TRIAL',
  headerIntro:
    '<h1>\n Barbercloud {linebreak} professional booking & management system\n </h1>\n <h2>\n An integrated booking and management system which makes your business transparent and easier to run. Make booking simple!\n </h2>\n <h2>\n <ul>\n <li>Cloud based</li>\n <li>Fast and secure</li>\n <li>Accessible anywhere, anytime</li>\n <li>For pc, tablet and phone</li>\n <li>Automated customer communication for free</li>\n </ul>\n </h2>',
  featuresTitle: 'Features',
  features1:
    '<FeatureTitle>Integrated booking system</FeatureTitle>\n <FeatureText>Swift and simple booking experience</FeatureText>\n <FeatureText>Employee rating and customer feedback</FeatureText>\n <FeatureText>Automated email & messenger communication</FeatureText>',
  features2:
    '<FeatureTitle>Shifts and statistics</FeatureTitle>\n <FeatureText>Monthly shifts and schedules made easy</FeatureText>\n <FeatureText>Manage days off and holidays</FeatureText>\n <FeatureText>Informative statistics</FeatureText>',
  features3:
    '<FeatureTitle>Administration</FeatureTitle>\n <FeatureText>Manage employees</FeatureText>\n <FeatureText>Manage products and services</FeatureText>\n <FeatureText>Inventory control</FeatureText>',
  features4:
    '<FeatureTitle>Day-to-day management</FeatureTitle>\n <FeatureText>Service and product sales</FeatureText>\n <FeatureText>Track product usage</FeatureText>\n <FeatureText>Identify potential misuse</FeatureText>',
  features5:
    '<FeatureTitle>Managerial reporting</FeatureTitle>\n <FeatureText>Summaries and performance reports</FeatureText>\n <FeatureText>Tracking transactions</FeatureText>\n <FeatureText>Sales statistics</FeatureText>',
  features6:
    '<FeatureTitle>Performance measurement</FeatureTitle>\n <FeatureText>Automated salary calculations</FeatureText>\n <FeatureText>Deductions, advance payments</FeatureText>\n <FeatureText>Payment rules set-up</FeatureText>',
  videos1: 'Daily management',
  videos2: 'Management reporting',
  videos3: 'Administration',
  videos4: 'Performance evaluation',
  videos5: 'Integrated booking system',
  videos6: 'Shifts and statistics',
  pricingPackages: 'Features',
  pricingPackageBasic: 'Basic system',
  pricingPackageFull: 'Booking & Management',
  pricingMonthlyPrice: 'Monthly price',
  pricingMonthlyPriceBasic: '£99',
  pricingMonthlyPriceFull: '£149',
  pricingFeature1: 'Day-to-day management',
  pricingFeature2: 'Complete administration',
  pricingFeature3: 'Management reporting',
  pricingFeature4: 'Sales statistics',
  pricingFeature5: 'Performance evaluation',
  pricingFeature6: 'Payroll',
  pricingFeature7: 'Inventory control',
  pricingFeature8: 'Limitless booking system',
  pricingFeature9: 'Shifts, scheduling and calendar',
  pricingFeature10: 'Email and messenger notifications',
  pricingFeature11: 'Employee rating system',
  pricingFeature12: 'Complete technical support',
  pricingTryForFree: 'START FREE TRIAL',
  pricingSubtitle: 'Pricing',
  pricingOption1: '1 Team Member',
  pricingOption2: '2-3 Team Member',
  pricingOption3: '4-5 Team Member',
  pricingOption4: '6-8 Team Member',
  pricingOption5: '8+ Team Member',
  pricingParagraph1:
    '<h2>Try out the Barbercloud booking system for free for 2 months:</h2>\n <p>No risk: No strings attached, no small prints, no hidden costs, no credit card required</p>\n <p>Quick to set up: It will take about 10 minutes to set up a fully functional booking system</p>\n <p>Quick to customise: After setup you can customise and add your branding to your booking site in minutes</p>\n <p>It doesn’t stop there: After your first 3 weeks of usage you get an integrated shop management system for free (managing sales, inventory, reporting, salaries, and many more!)</p>\n <h2>Immerse yourself in the Barbercloud experience!</h2>\n <p>We give you a booking system to begin with, then after your first 3 weeks we unlock your full shop management system. With your booking system already going, it is super convenient to ease into the management system and get a hang of it immediately. This is when you realise the full potential of Barbercloud and how easy it is to run your business - doing less but achieving more.</p>',
  aboutUs:
    '<GetStartedTitle>About us</GetStartedTitle>\n <P>\n Barbercloud Ltd. provides professional booking & management solutions for service providers (salons and many more). We use cutting edge technology in our cloud based systems to provide stable, reliable and easy to use solutions and a simple & swift booking experience for your customers.\n </P>\n <P>\n The Barbercloud system was developed based on the continuous feedback from our partners & customers. We serve a wide range of service providers across the EU and the UK.\n </P>\n <P>\n We are based in London, in the UK.\n </P>',
  contact:
    '<GetStartedTitle>Contact us</GetStartedTitle>\n <SubHeader>Sales and customer support</SubHeader>\n <Paragraph>Email: info@barbercloud.net</Paragraph>\n <Paragraph>Phone: +44 7723 567911</Paragraph>',
  companyDetails:
    '<SubHeader>Company information</SubHeader>\n <Paragraph>Barbercloud Ltd</Paragraph>\n <Paragraph>29 Crowland walk,</Paragraph>\n <Paragraph>Morden, SM4 6QH, United Kingdom</Paragraph>\n <Paragraph>Company number: 12470538</Paragraph>',
  navFeatures: 'Features',
  navPricing: 'Pricing',
  navReferences: 'References',
  navAboutUs: 'About us',
  navContact: 'Contact',
  pageSignupTitle: 'Try Barbercloud for 2 months for free!',
  pageSignupSubTitle:
    'Fill in your details and we will be in touch within 1 business day. We help setting up the system over the phone in an hour and you are good to go!',
  pageSignupFormTitle: 'Sign up',
  pageSignupFamilyName: 'Last name',
  pageSignupGivenName: 'First name',
  pageSignupShopName: 'Shop name',
  pageSignupWebsite: 'Shop website (if exists)',
  pageSignupEmail: 'Email address',
  pageSignupCurrentSystem:
    'Are you using any booking or management system currently? If yes, which one is it?',
  pageSignupCoupon: 'Promotion Code',
  pageSignupSubmitButton: 'Sign up',
  pageSignupTCs:
    'By signing up I accept the {linebreak}\n <Link>\n Terms and Conditions\n </Link>',
  pageReviewSuccess: 'We got your feedback.',
  pageReviewThankyou: 'Thank you for choosing us!',
  pageReviewReview: 'Rating:',
  pageReviewComment: 'Comment',
  pageReviewSubmit: 'Submit',
  pageReviewFeedbackRequired: 'Mandatory to give text review',
  pageResetPasswordTitle: 'Reset Password',
  pageResetPasswordWarningEmptyPassword: 'The password field cannot be empty',
  pageResetPasswordWarningPasswordMismatch:
    'The two passwords are not matching!',
  pageResetPasswordNewPassword: 'New password',
  pageResetPasswordNewPasswordRepeat: 'Confirm new password',
  pageForgotPasswordTitle: 'Forgot password',
  pageForgotPasswordEmailOrUsername: 'Account name',
  pageForgotPasswordSubmit: 'Submit',
  pageCancelBookingTitle: 'Are you sure you want to cancel your booking?',
  pageCancelBookingCancelBooking: 'Cancel booking',
  appLoginTitle: 'Login',
  appLoginUsername: 'Account name',
  appLoginPassword: 'Password',
  appLoginLoginButton: 'Login',
  appLoginForgotPasswordButton: 'Forgot password',
  appNavInput: 'Actions',
  appNavInputPurchase: 'New Sale',
  appNavInputOtherTransaction: 'Other Cash Flows',
  appNavInputProductUse: 'Product Use',
  appNavInputCloseTill: 'Cash up',
  appNavReport: 'Reports',
  appNavReportSummary: 'Summary',
  appNavReportPerformance: 'Performance',
  appNavReportTransactions: 'Transactions',
  appNavReportUsages: 'Product Usage',
  appNavReportTips: 'Tips Summary',
  appNavAdminShop: 'Admin Setup',
  appNavAdminShopServices: 'Services',
  appNavAdminShopEmployees: 'Employees',
  appNavAdminShopBookings: 'Bookings',
  appNavAdminShopProducts: 'Products',
  appNavAdminShopProductInventory: 'Inventory',
  appNavAdminShopMiscTransactions: 'Other Cash Flows',
  appNavAdminShopSettings: 'Settings',
  appNavAdminShopTillClosings: 'Cashier Closing Overview',
  appNavAdminBooking: 'Admin Booking Setup',
  appNavAdminBookingSettings: 'Booking Settings',
  appNavAdminBookingSchedules: 'Staff Schedules & Shifts',
  appNavAdminBookingStats: 'Statistics',
  appNavAdminBookingClients: 'Customers',
  appNavAdminBookingReviews: 'Reviews & Ratings',
  appNavAdminBookingWebsite: 'External Booking Link',
  appNavSalary: 'Admin Payroll Setup',
  appNavSalaryRules: 'Salary Rules',
  appNavSalaryAssignments: 'Rule Assignments',
  appNavSalaryDeductions: 'Deductions & Cash Advances',
  appNavSalarySalaries: 'Salaries',
  appNavBookings: 'Bookings',
  appNavAlertFreePeriodToExpire:
    'The free trial-period expires in {days} day(s)!',
  appNavAlertCooldownToExpire:
    'Late payment warning! If no payment is made within the next {days} day(s), the account will be freezed.',
  appNavLogout: 'Logout',
  appNavLogoutConfirmation: 'Are you sure you want to logout?',
  purchaseErrorTipAmountMismatch:
    'The amount with tip included cannot be lower than the original amount!',
  purchaseErrorNoItemAdded: 'There are no items added to the sale!',
  purchaseErrorProductWithGiftcard:
    'Products and Gift Cards cannot be paid for with Gift Cards! Make a separate sale for these.',
  purchaseCurrentTransaction: 'Current Transaction:',
  purchaseYesTip: 'Yes',
  purchaseNoTip: 'No',
  purchaseAmountWithTip: 'Total with tip',
  purchaseAmountWithTipOr: 'OR',
  purchaseTipAmount: 'Tip amount separately',
  purchaseConfirm: 'Confirm Sale',
  purchaseConfirmFullAmount: 'Amount Received',
  purchaseConfirmTipAmount: 'Tip Amount',
  purchaseAddItemsService: 'Add service',
  purchaseAddItemsProduct: 'Add product',
  purchaseAddItemsGiftcard: 'Add gift card',
  purchaseAddItemsBookings: 'Bookings',
  saleItemTableSum: 'Sum Amount',
  saleItemTableRounding: 'Rounding',
  saleItemTableTotal: 'Total',
  saleItemDialogTitle: 'Add a {kind}',
  tillCloseTitle: 'Cashflow since last cash up',
  tillCloseOpeningBalance: 'Starting amount',
  tillCloseDailyCashRevenue: 'Cash in',
  tillCloseDailyCashSalesRevenue: 'Of which sales',
  tillCloseDailyCashMiscRevenue: 'Of which other cash flows',
  tillCloseDailyCashExpense: 'Cash out',
  tillCloseDailyCashMiscExpense: 'Of which other cash flows',
  tillCloseDailyCashTillTakenOut: 'Of which cash banked',
  tillCloseDailyCashBalance: 'Expected ending balance',
  tillCloseNoTillCloseYet: 'Not yet cashed up today!',
  tillCloseTooMuchInTill: 'Cash overage!',
  tillCloseNotEnoughInTill: 'Cash shortage!',
  tillCloseMatches: 'Cash up matches!',
  tillCloseBanknote: 'Notes & coins',
  tillCloseCount: 'Count',
  tillCloseValue: 'Value',
  tillCloseTotal: 'Total',
  tillMissingTitle: 'Cash shortage split',
  tillMissingNoMissing: 'No Cash Shortage.',
  tillCloseInputTitle: 'Cash up',
  tillCloseInputCloseDate: 'Cash up date',
  tillCloseInputTakenOut: 'Amount to bank',
  tillCloseInputNotesLeftInTill: 'Notes & coins in till',
  bookingBarberInitialsUnassigned: 'ANY',
  bookingCancelled: 'DELETED',
  bookingCancelledBreak: 'Deleted break',
  bookingCalendarTime: 'Time',
  bookingCalendarEvent: 'Booking',
  bookingCalendarAllDay: 'Whole day',
  bookingCalendarWeek: 'Week',
  bookingCalendarWorkWeek: 'Work week',
  bookingCalendarDay: 'Day',
  bookingCalendarMonth: 'Month',
  bookingCalendarYesterday: 'Yesterday',
  bookingCalendarTomorrow: 'Tomorrow',
  bookingCalendarToday: 'Today',
  bookingCalendarAgenda: 'Agenda',
  bookingCalendarNoEventsInRange: 'There are no bookings in this period.',
  bookingClientNotArrived: 'No show.',
  bookingCancelDialogTitle: 'Cancel booking',
  bookingCancelDialogConfirmation:
    'Are you sure you want to cancel the booking?',
  bookingCancelDialogCancelButton: 'Cancel',
  bookingBookingTime: 'Time',
  calendarToolbarTitle: 'Employees',
  calendarToolbarAllColleagues: 'All employees',
  calendarCancelledBookingTitle: 'Cancelled bookings',
  calendarCancelledBookingPerson: 'Cancelled by',
  calendarCancelledBookingCancelTime: 'Time of cancellation',
  calendarCancelledBookingBookedTime: 'Booked time',
  calendarCancelledBookingCancelReason: 'Reason for cancellation',
  calendarCancelledBookingBookedToBarber: 'Booked with',
  calendarCancelledBookingBookedService: 'Booked service',
  calendarCancelledBookingClient: 'Customer',
  calendarCancelledBookingDetailsDialogTitle: 'Booking details',
  calendarCancelledBookingDetailsDialogBarberSelected: 'selected team member',
  calendarCancelledBookingDetailsDialogBarberNotSelected: 'any team member',
  calendarCancelledBookingDetailsDialogCustomer: 'Customer',
  startDateEndDateFilterEarlyDateError: 'Give a later date and time',
  startDateEndDateFilterFromLabel: 'from',
  startDateEndDateFilterToLabel: 'to',
  summaryRevenueTitle: 'Total revenue',
  summaryRevenueCard: 'Card payment revenue',
  summaryRevenueCardService: 'Of which services',
  summaryRevenueCardProduct: 'Of which product sales',
  summaryRevenueCardGiftcard: 'Of which gift card sales',
  summaryRevenueCash: 'Cash revenue',
  summaryRevenueCashService: 'Of which services',
  summaryRevenueCashProduct: 'Of which product sales',
  summaryRevenueCashGiftcard: 'Of which gift card sales',
  summaryRevenueMiscRevenue: 'Other incoming cashflows',
  summaryRevenueMiscExpense: 'Other outgoing cashflows',
  summaryCustomerTitle: 'Total Customers',
  summaryCustomerSum: 'Total',
  summaryCustomerGifcard: 'Gift Card',
  summaryCustomerCard: 'Card Payment',
  summaryCustomerCash: 'Cash',
  summaryCustomerPerColleague: 'Per Employee',
  summaryMiscCashTitle: 'Other Cashflows',
  summaryMiscCashKind: 'Pay In / Pay Out',
  summaryUsageTitle: 'Product Usage',
  summarySalesTitle: 'Total Sales',
  summarySalesDiscountedSales: 'Discount sales',
  summarySalesProductService: 'Product / Service',
  tableFilterLabel: 'Filter (min. 3 characters)',
  calendarEditDialogClientNameMissing: 'Please fill in your name!',
  calendarEditDialogInvalidEmail: 'Incorrect email!',
  calendarEditDialogTitleMissing: 'Missing title!',
  calendarEditDialogDurationMissing: 'Incorrect lenght of booking!',
  calendarEditDialogEdit: 'Edit booking',
  calendarEditDialogNew: 'New booking',
  calendarEditDialogKind: 'Booking or break',
  calendarEditDialogBooking: 'Booking',
  calendarEditDialogBreak: 'Break',
  calendarEditDialogSelectTime: 'Choose a time',
  calendarEditDialogSelectBarber: 'Choose an employee',
  calendarEditDialogDuration: 'Duration',
  calendarEditDialogSelectService: 'Choose a service',
  calendarEditDialogClientDetails: 'Customer details',
  calendarEditDialogBreakDetails: 'Break details',
  calendarEditDialogExistingCustomers: 'Existing customers',
  calendarEditDialogExistingCustomerResults: 'match(es)',
  calendarEditDialogExistingWaitingForInput: 'Start to type in customer info',
  employmentKind: 'Employment type',
  performanceServiceTitle: 'Services',
  performanceServiceServiceRatio: 'Ratio of services from total personal sales',
  performanceProductTitle: 'Products',
  performanceProductProductRatio: 'Ratio of products from total personal sales',
  performanceProductServiceTitle: 'Services and Products',
  performanceProductServicePersonalRatio:
    'Ratio of personal sales from total sales',
  performanceStatsTitle: 'Performance Statistics',
  performanceStatsDaysWorked: 'No. of work days',
  performanceStatsCustomerCount: 'No. of customers',
  performanceStatsProductsSold: 'No. of products sold',
  performanceStatsDailyAvgCustomerCount: 'Average daily customers',
  performanceStatsDailyAvgProductSoldCount: 'Average daily products sold',
  performanceStatsDailyAvgCustomerValue:
    'Average customer sales value (only services)',
  performanceServiceSaleTitle: 'Service Sales Statistics',
  performanceProductSaleTitle: 'Product Sales Statistics',
  performanceShortages: 'Deficit Allocations',
  transactionsInputKind: 'Input Type',
  transactionKindMiscPayin: 'Other incoming cashflows',
  transactionKindMiscPayout: 'Other outgoing cashflows',
  transactionKindService: 'Service sales',
  transactionKindProduct: 'Product sales',
  transactionKindGiftcard: 'Gift card sales',
  transactionKindMixed: 'Mixed sales',
  transactionKindNull: 'Unknown',
  transactionDetailsTitle: 'Transaction details',
  transactionDetailsVoided: 'VOIDED',
  selectColleague: 'Select employee',
  purchaseTableToPay: 'Amount to pay',
  usageDetailsTitle: 'Product usage details',
  tipsAllTitle: 'Total tips',
  tipsCashTitle: 'Cash tips',
  tipsCardTitle: 'Card payment tips',
  tooltipAdminServices:
    'Add new services or edit existing ones. You can set up how complex your services are, by modifying them.',
  serviceEdit: 'Edit service',
  serviceAdd: 'Add new service',
  serviceMultipleOf15Error: 'It must be a multiple of 5 mins!',
  serviceAlreadyExists: 'There is already a service with this name!',
  serviceNameDescription: 'Name and Description',
  serviceNameDescriptionTooltip:
    'The service name is mandatory. The description only shows on the booking page.',
  barcodeTooltip: 'Add barcode for the product or service!',
  servicePriceDuration: 'Price and Duration',
  servicePriceDurationTooltip:
    "Price and duration is mandatory. With Exact Price you can control if your booking page should show the exact amount or add 'from' before it. E.g. '20 GBP' or 'From 20 GBP'.",
  serviceDisplay: 'Appearance',
  serviceDisplayTooltip:
    'Optional, it defines the order in which the services are shown on the booking page. Smaller numbers are shown first. You will see the services in the selected colour in your Bookings menu.',
  serviceDisplayOrder: 'Display order on booking page',
  serviceDefaultLevel: 'Default',
  colorPickerAssignedColour: 'Colour',
  tooltipAdminProducts:
    'To track your product usage and/or product sales, add new products or edit existing ones here.',
  productEdit: 'Edit product',
  productAdd: 'Add new product',
  productAlreadyExists: 'There is already a product with this name.',
  tooltipAdminEmployee:
    'Add new employees or edit existing ones. You can set up which services are provided by each employee. You can also set up experience levels for your employees, having different price and duration for their services, you can do this in the Settings menu under Admin Setup.',
  employeeRole: 'Role',
  employeeEdit: 'Edit employee',
  employeeAdd: 'Add new employee',
  employmentKindEmployee: 'Employee',
  employmentKindContractor: 'Contractor',
  employmentRoleBarber: 'Team Member',
  employmentRoleManager: 'Manager',
  employmentErrorNameMissing: 'The employee must have a name.',
  employmentErrorAlreadyExists: 'There is already an employee with this name!',
  employmentErrorAvailability: 'Wrong schedule',
  employmentBaseDetails: 'Basic Information',
  employmentBaseDetailsTooltip:
    'Basic information of the employee, email is optional.',
  employmentBreaks: 'Recurring breaks',
  employmentBreaksTooltip:
    'Optional, you can define recurring breaks for each employee separately. These will show up in their calendar for every day they are working and it is not possible to book for these times for the given team member. For example, the given team member has lunch at 14:00 every day for 30 mins.',
  employmentAddBreak: 'Add new break',
  employmentAddBreakChooseTime: 'Choose a time',
  duration: 'Duration',
  employmentProfileTitle: 'Employee Profile',
  employmentProfileTooltip:
    'You can upload a profile picture which will show on the booking page fore the employee. Recommended to use a portrait photo including the torso and head. You can add the instagram link of the employee which will show on their profile page on the booking site.',
  employmentNewProfilePicture: 'Add new profile picture',
  employmentInstagramLink: 'Instagram link',
  employmentShowProfile: 'Show employee on the booking page',
  employmentServicesTooltip:
    'You can select which services can be booked for the given employee. By default everything can be booked to everyone.',
  employmentPortfolioImagesTitle: 'Portfolio pictures',
  employmentPortfolioImagesTooltip:
    'You can add several pictures of the work done by the employee. Recommended to upload 2, 4 or 6 photos.',
  employmentPortfolioImagesUpload: 'Add new picture',
  employeeDefaultServiceLevel: 'Default level',
  inventoryTooltip:
    'You can track your stock for all products. You can see how many were added for each product and when, how many were used during service providing, how many were sold. You can add to your stock with the ADD PRODUCTS button and you can do inventory taking with the STOCK-TAKING button.',
  inventoryAddProducts: 'Add products',
  inventoryDoInventory: 'Stock-taking',
  inventoryTableTitle: 'Inventory',
  inventoryProductName: 'Product name',
  inventoryQuantityUsed: 'Used quantity',
  inventoryQuantitySold: 'Sold quantity',
  inventoryQuantityInput: 'Added quantity',
  inventoryQuantityBalance: 'Current stock',
  inventoryTableInputTitle: 'Product quantity changes',
  inventoryInputDetails: 'Details',
  inventoryInputQuantityToAdd: 'Quantity to add',
  inventoryCurrentQuantity: 'Current quantity',
  inventoryConfirmInput: 'Confirm',
  inventoryPreviousQuantity: 'Previous quantity',
  inventoryNewQuantity: 'New quantity',
  inventoryChangeQuantity: 'Change in quantity',
  miscTransactionTooptip:
    'Summary of all the other cashflows by categories for the selected period. You can add or delete new other categories, like cleaning, etc.',
  miscTransactionTableTitle: 'Other Cashflow Summary',
  miscTransactionCategories: 'Other Cashflow Categories',
  miscTransactionCategoryName: 'Category name',
  miscTransactionCategoriesAdd: 'Add new category',
  miscTransactionCategoriesDelete: 'Delete category',
  miscTransactionCategoriesMove:
    'Move old transactions under this category into the selected category',
  miscTransactionCategoriesNew: 'New category',
  settingsTill: 'Cashier settings',
  settingsBarberLevels: 'Team member levels',
  settingsBarberLevelsAdd: 'Add new level',
  settingsBarberLevelsAddLevelLabel: 'Name of level',
  settingsRollBalance: 'Roll cashier surplus or deficit',
  settingsRollBalanceTooltip:
    'After cashier closing, if there is a surplus or deficit, it will be rolled over to the next day or not.',
  settingsPermissions: 'Shop User Permissions',
  settingsAllowDateFiltering: 'Team members can filter for past periods',
  settingsAllowDateFilteringTooltip:
    'If not selected, employees using the shop-account will not be able to see previous days or periods, only the current day.',
  settingsUploadLogo: 'Upload new logo',
  settingsUploadLogoTooltip:
    'You can add your own logo in the top left corner of this tool.',
  adminTillTooltip:
    'Here you can check all the past cashier closings, clicking on the details for a day will show you all the closes for that given day.',
  adminTillListTitle: 'Cashier closes',
  adminTillListDailyTitle: 'Daily closes',
  inputDatetime: 'Time',
  adminTillListCloseDay: 'Balancing date',
  adminTillAmountTaken: 'Cash deposited into bank',
  adminTillChangeAmount: 'Cash left in cash drawer',
  adminTillCloseCount: 'No. of cashier closes',
  adminTillNotesInCounter: 'Notes & coins in cash drawer',
  bookingSettingsTooltip:
    "This is where you can customize and brand your booking system and your external booking site that your customers use to book with you.\n \n There are many options here and you will find more information about them at their '?' marks.\n \n Make sure to save your changes at the bottom of the page before you navigate away!\n \n IMPORTANT: In case you change anything on the Basic Information section, make sure to save and then re-generate your Privacy Policy via the EDIT PRIVACY POLICY button (which also needs to be saved at the bottom of the page after re-generation or modification. In case you are not happy with the Privacy Policy we drafted for you, feel free to change its text via the editor, and again, don't forget to save the page after your edit.",
  noMinimum: 'No minimum',
  bookingSettingsErrorOpeningHours: 'Incorrect opening hours!',
  bookingSettingsBasicInfo: 'Basic information',
  bookingSettingsBasicInfoTooltip:
    'Fill in the company information. All fields are mandatory.',
  bookingSettingsShopName: 'Shop / Salon name',
  bookingSettingsCompanyName: 'Company name',
  bookingSettingsTaxReference: 'VAT number (if exists)',
  bookingSettingsPhoneNumber: 'Telephone number',
  bookingSettingsEmail: 'Company email address',
  bookingSettingsShopAddress: 'Shop / Salon address',
  bookingSettingsPostAddress: 'Postal address',
  bookingSettingsPostalCode: 'Postcode',
  bookingSettingsTown: 'City',
  bookingSettingsAddressLine1: 'Shop / Salon address',
  bookingSettingsAddressSame: 'Same as shop address',
  bookingSettingsFacebook: 'Facebook',
  bookingSettingsInstagram: 'Instagram',
  bookingSettingsMetaDescription: 'Google search preview text',
  bookingSettingsMetaDescriptionTooltip:
    'You can add your customised google search preview text here.',
  bookingSettingsOpening: 'Opening hours',
  bookingSettingsOpeningTooltip:
    'You have to set the opening hours for every day of the week. you can punch in the times or select with the calendar icon.',
  bookingSettingsPrivacyPolicy: 'Privacy Policy',
  bookingSettingsPrivacyPolicyTooltip:
    'It is important that all the company information is filled out and the page is saved at the bottom with the SAVE button, before generating the Privacy Policy. The system will auto-generate your Privacy Policy based on the company information, but you can edit this as per your liking. IMPORTANT TO NOTE: it is your responsibility to ensure that the Privacy Policy is fit for purpose and legally correct.',
  bookingSettingsPrivacyPolicyEditButton: 'Edit Privacy Policy',
  bookingSettingsPrivacyPolicyEditTitle: 'Edit Privacy Policy',
  bookingSettingsPrivacyPolicyHeading:
    'It is the responsibility of the company to ensure that the Privacy Policy is fit for purpose and legally correct.',
  bookingSettingsPrivacyPolicyReGenerate: 'Re-Generate',
  bookingSettingsPrivacyPolicyCreate: 'Create Privacy Policy',
  bookingSettingsPrivacyPolicyPlaceandDate: 'Time and city of creation',
  bookingSettingsPrivacyPolicyGenerate: 'Generate',
  bookingSettingsPrivacyPolicyCreationHeading:
    'It is your responsibility to ensure that the Privacy Policy is fit for purpose and legally correct.',
  bookingSettingsCloseDates: 'Add holidays',
  bookingSettingsCloseDatesTooltip:
    'You can add holidays when the shop is closed. You cannot schedule shifts for these days.',
  bookingSettingsBackground: 'Booking background',
  bookingSettingsBackgroundNew: 'Upload new picture',
  bookingSettingsBackgroundTooltip:
    'You can set the background picture for the booking website',
  bookingSettingsBanner: 'Upload email banner picture',
  bookingSettingsBannerNew: 'Upload new email banner picture',
  bookingSettingsBannerTooltip:
    'All of your email notifications can be used for advertising. Add a banner picture & link to the bottom of your email notifications. You can use this feature to run campaigns, sales, or to advertise yourself or anyone else. Really its up to you :)',
  bookingSettingsBannerText:
    'The banner picture must be one of the following sizes: Small (400 x 100 pixels) or Large (400 x 150 pixels). In case the size differs, the system will automatically resize it. To ensure the best outcome, please stick with these sizes.',
  bookingSettingsBannerSize: 'Banner size',
  bookingSettingsBannerSizeSmall: 'Small (400 x 100 pixels)',
  bookingSettingsBannerSizeLarge: 'Large (400 x 150 pixels)',
  bookingSettingsBannerSelectEmails:
    'Select notifications that will contain the banner',
  bookingSettingsBannerLink: 'Link the banner redirects to',
  bookingSettingsReviewSettings: 'Employee review settings',
  bookingSettingsEmailReview: 'Email notification to ask for customer reviews',
  bookingSettingsEmailReviewTooltip:
    'The system will send an automated notification to the customers asking them to review the service they received. Review happens with a 5 star system and comment option.',
  bookingSettingsEmailReviewPeriod:
    'How long after their booking should we ask for review?',
  bookingSettingsAutoAccept4StarReviews:
    'All 4 star ratings without comments are accepted automatically.',
  bookingSettingsAutoAccept5StarReviews:
    'All 5 star ratings without comments are accepted automatically.',
  bookingSettingsBookingSettings: 'Booking settings',
  bookingSettingsMinimumBookingWaitTime: 'Earliest booking',
  bookingSettingsMinimumBookingWaitTimeTooltip:
    'This is the minimum time window before a booking. For example if set for 30 mins, the customer cannot book for a slot which starts in 10 mins. Best to leave at no minimum.',
  bookingSettingsMaximumBookingWaitTime: 'Latest booking',
  bookingSettingsMaximumBookingWaitTimeTooltip:
    'This is the latest time in the future for which you want to receive bookings. Recommended to set to a long time, bookings can only happen for days you have set a employee schedule anyway.',
  bookingSettingsMinimumCancellationTime: 'Cancellation time window',
  bookingSettingsMinimumCancellationTimeTooltip:
    'This is the time window before the booked appointment, before which the customer can cancel the booking. for example if set to 2 hours, the customer cannot cancel the booking 1.5 hours before her/his appointment.',
  bookingSettingsBookingInterval: 'Customers can book for every',
  bookingSettingsBookingIntervalTooltip:
    'This is the frequency of time slots available to the customers for booking. For example if set to 15 mins, in every hour there will be 4 slots available; if set to 30 minutes, every hour will have 2 slots available.',
  bookingSettingsEmailReminderEnabled: 'Send automated booking reminders',
  bookingSettingsEmailReminderEnabledTooltip:
    'You can set the system to send automated email and facebook messenger reminders to the customer about their booking. You can also set how long before the booked time should they receive the reminder.',
  bookingSettingsEmailReminderPeriod:
    'How much time before the appointment should the reminder be sent?',
  bookingSettingsEmailNotificationEnabled:
    'Do you want to be notified about every booking?',
  bookingSettingsEmailNotificationEnabledTooltip:
    'If selected, the system will send an automated email about every booking and the booking details to the email address given below.',
  bookingSettingsEmailNotificationEmailAddress:
    'Email address to receive notifications of ALL bookings',
  bookingSettingsEmailBarberNotificationEnabled:
    'Do you want your team members to be notified if they get a booking?',
  bookingSettingsEmailBarberNotificationEnabledTooltip:
    "If selected, the system will send an automated email about every booking and the booking details to the booked employee's email address given on the Employees menu.",
  bookingSettingsSaveChanges: 'SAVE',
  bookingSettingsonlyReviewsWithFeedback: 'Customers must give text review',
  bookingSettingsonlyReviewsWithFeedbackTooltip:
    'This option restricts customer reviews so that they must contain a written review as well.',
  bookingSchedulesTooltip:
    "You can set up your staff schedules in minutes with ease. The first column shows your team members and the number of their working days in the month. The last row shows the total number of team members for each given day. You can set up daily schedules by clicking in a cell, or you can set monthly schedules by clicking on a team member's name.",
  bookingSchedulesKind: 'W/NW/H',
  bookingSchedulesIsOpening: 'O',
  bookingSchedulesIsClosing: 'C',
  bookingSchedulesIsOpeningAndClosing: 'O/C',
  bookingSchedulesDayStart: 'Shift start',
  bookingSchedulesDayEnd: 'Shift end',
  bookingSchedulesWorkKind: 'Working',
  bookingSchedulesWorking: 'Working (W)',
  bookingSchedulesNotWorking: 'Not Working (NW)',
  bookingSchedulesHoliday: 'Holiday (H)',
  bookingSchedulesOpening: 'Opens the shop',
  bookingSchedulesClosing: 'Closes the shop',
  bookingSchedulesLegend: 'Explanation',
  bookingSchedulesShopClosed: 'Shop closed',
  bookingSchedulesShopOpening: 'Opens the shop',
  bookingSchedulesShopClosing: 'Closes the shop',
  bookingSchedulesNumberOfEmployees: 'Number of employees',
  statsTooltip:
    'This is your statistics and business intelligence center for your business. It will get more exciting once you start getting bookings and the charts fill up with data!\n \n We are constantly improving & extending these to support your business decision making.',
  statsAllBooking: 'No. of bookings',
  statsKpiCancelledBooking: 'Deleted bookings',
  statsKpiNoShow: 'No-Shows',
  statsKpiInternalBooking: 'Internal bookings',
  statsRatioReturningCustomers: 'Returning customers',
  statsRatioOnlineBookings: 'Online bookings',
  statsRatioBarberSelected: 'Customer selected team member',
  clientsTooltip:
    "Handling personal information effectively and securely is crucial for your business. You can review, delete or extract your customers' data on this page.\n \n We also give you the right tools to deal with any GDPR related customer requests. Don't forget that your customers can request you to:\n - provide all information you collect about them (filter for the customer, then you can use the top right cloud button to download)\n - delete all information you collect about them (open customer details and use the delete button)\n \n Don't forget that you should not share this customer data with anyone.",
  clientsClientName: 'Customers name',
  clientsLastBookingDate: 'Last booking date',
  clientsLastBookingService: 'Last booked service',
  clientsLastBookingBarber: 'Last booked to employee',
  clientsClientDetails: 'Customer details',
  clientsClientEmail: 'Customer email',
  clientsClientPhone: 'Customer phone',
  clientsFirstBookingDate: 'First booking',
  clientsBookingDate: 'Booking date',
  clientsBookingTime: 'Booking time',
  clientsBookingSource: 'Booking source',
  clientsBookingBarber: 'Booked with employee',
  clientsBookingSourceInternal: 'Internal booking',
  clientsBookingSourceExternal: 'Online booking',
  clientsDeleteClient: 'Delete customer data',
  clientsDeleteConfirmation:
    'The customer data will be deleted permanently, are you sure you want to delete?',
  reviewsTooltip:
    "You can review, accept or reject customers reviews on this page. You can also use our in-built SEND RESPONSE button if you want to contact your customers regarding their review. Please note that you only need to add the core text here, we will take care of addressing your customer and signing your message in the name of your shop.\n \n Accepted reviews will show up on your booking site, at the team member's profile in about 5 minutes after accepting.\n \n It is a good idea to check reviews every couple of days to ensure you don't build a large backlog.",
  reviewsUnprocessed: 'Received Reviews',
  reviewsApproved: 'Accepted Reviews',
  reviewsDenied: 'Rejected Reviews',
  review: 'Review',
  reviewDetails: 'Review details',
  reviewClientDetails: 'Customer details',
  reviewAllBookingCount: 'Number of total bookings',
  reviewBookingDetails: 'Booking details',
  reviewToWho: 'Employee',
  reviewToWhen: 'Time',
  reviewToWhatService: 'Service',
  reviewReviewDetails: 'Review details',
  reviewSendResponse: 'Send a response',
  bookingSendMessage: 'Send Message',
  reviewAccept: 'Accept Review',
  reviewDeny: 'Reject Review',
  barberName: 'Employee name',
  send: 'Send',
  response: 'Response',
  message: 'Message',
  reviewSendResponseConfirm: 'Confirm send response',
  reviewSendResponsePreview: 'Message details',
  reviewSendResponseConfirmMessage: 'Message',
  bookingSendMessageConfirm: 'Confirm sending message',
  bookingSendMessagePreview: 'Message details',
  bookingSendMessageConfirmMessage: 'Message',
  websiteTooltip:
    "You can customise your booking URL (Custom identifier), Google search preview text and create any custom message to be shown on your booking page.\n \n Don't forget to save your changes before you navigate from this page.",
  website: 'Website',
  open: 'Open',
  salaryRulesTooltip:
    'You can create payroll rules here that can be assigned to employees. Select a rule type, name it and calibrate as per your liking. You can create as many rules as you want, and you can assign multiple rules to any of your team members. This enables you to create customised final payroll calculations for your team members, and you can even create very complex rule combinations as well. You can do the rule assignments in the Rule Assignments menu.\n \n Contractor with own bank terminal rule type helps if you have a contractor team member who uses their own terminal for their sales. In this case, this rule will keep track of their bank card sales and substract it from the final salary.',
  salaryRulesAddNew: 'Create new rule',
  fixsalaryrule: 'Fix salary',
  performancebasedsalaryrule: 'Performance based rule',
  tieredsalaryrule: 'Lump sum performance bonus',
  tieredprogressivesalaryrule: 'Progressive performance based banded salary',
  deductcardpaymentfromsalaryrule: 'Contractor with own bank card terminal',
  deductcardpaymentfromsalaryruleTooltip:
    'Employees with their own bank card terminal might need to have all their card based payments deducted from their calculated salary.',
  salaryRules: 'Rules',
  salaryName: 'Name of rule',
  salaryBase: 'Base of salary',
  salaryType: 'Type of rule',
  salaryAmount: 'Amount',
  salaryRule: 'Rules',
  salaryCardServiceNet: 'Card Payment services net amount',
  salaryCardServiceGross: 'Card Payment services gross amount',
  salaryCardProductNet: 'Card Payment product sales net amount',
  salaryCardProductGross: 'Card Payment product sales gross amount',
  salaryGiftcardServiceNet: 'Gift card services net amount',
  salaryGiftcardServiceGross: 'Gift card services gross amount',
  salaryCashServiceNet: 'Cash services net amount',
  salaryCashServiceGross: 'Cash services gross amount',
  salaryCashProductNet: 'Cash product sales net amount',
  salaryCashProductGross: 'Cash product sales gross amount',
  salaryDeductionBase: 'Payment deduction base',
  salaryTieredSalaryRuleDialogTitle: 'Lump sum performance bonus',
  salaryProvideName: 'Add rule name!',
  salarySalaryBase: 'Define the base of calculation!',
  salaryTierHelper:
    'Add the value limits of the bands and the respective amount for reaching them.',
  salaryTierMax: 'Value limit',
  salaryTierBonus: 'Bonus',
  salaryTierBonusPercentage: 'Percentage',
  salaryTierLimit: 'Limit',
  salaryTierPercentage: 'Percentage of performance',
  salaryProgressiveTieredTitle: 'Progressive performance based banded salary',
  salaryPerformanceBasedTitle: 'Performance based rule',
  salaryPerformancePercentage: 'Percentage of salary base',
  salaryFixTitle: 'Fix daily or monthly salary',
  salaryAssignmentsTooltip:
    'Here you can assign the created salary rules to the employees. You can assign multiple rules to the same employee.',
  salaryAssignmentsNew: 'New assignment',
  salaryAssignmentsAssign: 'Assign',
  salaryAssignmentsAssignedRules: 'Assigned rules',
  salaryDeductionTooltip:
    'You can make deductions or cash advances from the salary, which will be calculated into the final salary.',
  salaryDeductionDeduction: 'Deduction',
  salaryDeductionAdvance: 'Cash Advances',
  salaryDeductionDeductionNew: 'New deduction',
  salaryDeductionDeductionDeductedBy: 'Employee creating the item',
  salaryDeductionDeductionDeductFrom: 'Affected employee',
  salaryDeductionDeductionDate: 'Date of deduction',
  salaryDeductionAdvanceDate: 'Date of cash advance',
  salaryDeductionAdvanceNew: 'New cash advance',
  salaryDeductionAdvanceCashFromTill: 'Paid from the cashier?',
  salaryDeductionList: 'Deductions & Cash Advances',
  salaryDeductionDetails: 'Details of deduction',
  salaryDeductionDetailsDeductedBy: 'Employee who created the item',
  deletionDate: 'Date of deletion',
  deletionBy: 'Deleted by',
  salarySalariesTableTitle: 'Salary calculation',
  salarySalariesTooltip:
    'This page show the calculated total salaries, and amount to be paid, based on the rules, assignments, deductions and cash advances for the selected period.',
  salarySalariesTotalSalary: 'Total Salary',
  salarySalariesPartialSalary: 'Subtotal',
  layoutDatetimeFormat: 'DD/MM/YYYY dddd',
  customUrl: 'Custom link identifier',
  bookingBook: 'Booking',
  shopDetailsBooking: 'Booking',
  shopDetailsBookNow: 'Book now',
  bookingBarbers: 'Barbers',
  bookingBarbersMore: 'More',
  bookingAboutUs: 'About Us',
  bookingContact: 'Contact',
  bookingOpeningHoursMonday: 'Monday',
  bookingOpeningHoursTuesday: 'Tuesday',
  bookingOpeningHoursWednesday: 'Wednesday',
  bookingOpeningHoursThursday: 'Thursday',
  bookingOpeningHoursFriday: 'Friday',
  bookingOpeningHoursSaturday: 'Saturday',
  bookingOpeningHoursSunday: 'Sunday',
  bookingOpeningHoursClosed: 'Closed',
  bookingBarbersBook: 'Book now',
  bookingBookNoPreference: 'Any Barber',
  bookingBookServiceStepTitle: 'Select a Service',
  bookingBookExtrasStepTitle: 'Add additional services',
  bookingBookServiceFormat: '{service} (about {duration} mins) - {price}',
  bookingBookBarberStepTitle: 'Select a barber',
  bookingBookDateStepTitle: 'Select date & time',
  bookingBookCustomerStepTitle: 'Your details',
  bookingBookBackService: 'Back to Services',
  bookingBookBackExtras: 'Modify additional services',
  bookingBookBackBarber: 'Back to Team Members',
  bookingBookBackDate: 'Back to Date & Time',
  bookingErrorTCs: 'You need to accept the Privacy Policy',
  bookingErrorName: 'Your name',
  bookingErrorEmail: 'Your email address',
  bookingErrorInvalidEmail: 'Wrong email!',
  bookingErrorMobile: 'Your phone number',
  bookingService: 'Service',
  bookingBarber: 'Barber',
  bookingAppointmentDate: 'Date:',
  bookingAppointmentTime: 'Time:',
  bookingAppointmentAm: 'Morning',
  bookingAppointmentPm: 'Afternoon',
  bookingAppointmentEvening: 'Evening',
  bookingAppointmentNoFreeSlots:
    "We're sorry, there are no available slots for this day.",
  bookingCustomerName: 'Name',
  bookingCustomerEmail: 'Email',
  bookingCustomerMobile: 'Phone',
  bookingCustomerAcceptTCs:
    'By booking, you accept our Privacy Policy that you can read <A>here</A>',
  bookingCustomerRememberDetails: 'Remember me',
  bookingCustomerBook: 'Book',
  bookingDefaultSEOTitle: 'Book',
  bookingFollowSteps: 'Please follow the below steps to book an appointment.',
  barberReviewsBackToProfile: 'Back to the profile',
  bookingSuccessDialogTitle: 'Booking Confirmed',
  bookingSuccessDialogContent:
    '<DialogHeading>Thank you for your booking!</DialogHeading>\n <DialogText>You will also receive an email confirmation soon.</DialogText>',
  bookingCancelSuccessDialogTitle: 'Cancellation Confirmed',
  bookingCancelSuccessDialogContent:
    '<DialogHeading>Successful cancellation.</DialogHeading> <DialogText>You also received an email confirmation about your cancellation.</DialogText>',
  snackbarInputSuccess: 'Successful submission!',
  snackbarCloseTillSuccess: 'Successful cashier close!',
  snackbarProductUseInputSuccess: 'Successful submission!',
  snackbarVoidSuccess: 'Successful void!',
  snackbarModifySuccess: 'Successful edit!',
  snackbarCreateSuccess: 'Success!',
  snackbarDeleteSuccess: 'Successful delete!',
  snackbarAddSuccess: 'Successfully added!',
  snackbarAddProductInventorySuccess: 'Successfully added!',
  snackbarAddProductInventoryError: 'There is an error with the entry!',
  snackbarAddRuleSuccess: 'Successfully added rule!',
  snackbarAssignRuleSuccess: 'Successful rule assignment!',
  snackbarUnassignRuleSuccess: 'Rule assignment deleted!',
  snackbarBookingSuccess: 'Successful booking!',
  snackbarLoginError: 'Error - check your credentials!',
  snackbarLogoutSuccess: 'Logout successful!',
  snackbarForgotPasswordSent: 'Request sent!',
  snackbarResetPasswordSuccess: 'Successful password reset!',
  snackbarResetSignupSuccess: 'Thank you for signing up!',
  snackbarResetSignupError: 'There is an error with the entry!',
  bookingEmailSlugConfirmationEmail: 'Booking confirmation email',
  bookingEmailSlugReminderEmail: 'Booking reminder email',
  bookingEmailSlugCancellationEmail: 'Booking cancellation email',
  bookingEmailSlugReviewReminderEmail: 'Customer review email',
  appNavHelp: 'Help',
  bookingMoreServices: 'Show more',
  bookingMoreReviews: 'Show more',
  bookingSettingsWarningMessage: 'Custom message on booking page',
  bookingSettingsWarningMessageTooltip:
    'You can set a custom message to show on the booking site - useful for temporary messages, price changes, etc.',
  barberMinimumServicePriceFormat: 'from {price}',
  tooltipSettingsBarberLevels:
    "This option enables differentiated pricing and duration for your services across your team, based on their experience level.\n \n1. You can add any number of levels (like Master, Expert, Beginner, etc..), which will show up on your booking page when a customer selects the Team Members. Don't forget to save this page before you leave.\n \n2. After creating the levels here, you can set up separate pricing and duration for each service for each level in the Services Menu.\n\n3. Finally, you can select the different levels of services for each Team Member in the Employees Menu.\n \n As always, don't forget to save your changes before leaving!",
  tooltipViewVideo: 'Click to view video.',
  tooltipBookings1: 'Managing bookings - interactive and intuitive.',
  tooltipBookings2: 'Creating bookings & breaks internally - in seconds.',
  extraBookingSelectExtraBarbers:
    'Select Team Member for the additional services',
  serviceNextServicesTitle: 'Additional services',
  serviceNextServicesTooltip:
    'This is where you can enable your customers to book multiple services together.\n\n1. In order to enable this, set up this table for all of your services.\n\n2. You have to define for each service if the other services are coming before or after it. Like washing should come before blow dry.\n\n3. You can use the restrict option to disable choosing those two services together.',
  serviceNextServicesBeforeAfter: 'Before / After / Restrict',
  claimEmployee: 'Team Member',
  claimEmployees: 'Team Member',
  claimTitle: 'Some things in life should be simple',
  claimSubtitle: '- Your booking experience is one of them.',
  claimSubtitle2:
    'Complete the missing info to claim your free booking system. No credit card required.',
  claimFormName: 'Your name:',
  claimFormShopName: 'Your salon/shop name:',
  claimFormEmail: 'Your email address:',
  claimFormPhone: 'Your phone number:',
  claimCTA: 'CLAIM YOUR BOOKING SYSTEM',
  claimAcceptTCs:
    'By clicking the button, you accept the <LinkPP>Privacy Policy</LinkPP> and the <LinkTC>Terms and Conditions</LinkTC> of Barbercloud Ltd.',
  claimSubHeading1: 'Booking is a breeze with Barbercloud:',
  claimSubHeading2: 'Some important information about your booking system:',
  claimSubHeading2Li1:
    '<Bold>No risk:</Bold> No strings attached, no small prints, no hidden costs.',
  claimSubHeading2Li2:
    '<Bold>Quick to set up:</Bold> Starting now, it will take about 10 minutes to set it up.',
  claimSubHeading2Li3:
    '<Bold>Quick to customise:</Bold> After setup you can customise and add your branding to your booking site in minutes.',
  claimSubHeading2Li4:
    '<Bold>It doesn’t stop there:</Bold> After your first 3 weeks of usage you get an integrated shop management system for free (managing sales, inventory, reporting, salaries, and many more!).',
  claimSubHeading3: 'Immerse yourself in the Barbercloud experience!',
  claimSubHeading3p:
    'We give you a booking system to begin with, then after your first 3 weeks we unlock your full shop management system. With your booking system already going, it is super convenient to ease into the management system and get a hang of it immediately. This is when you realise the full potential of Barbercloud and how easy it is to run your business - doing less but achieving more.',
  claimSubHeading4:
    'The following fees are applicable after your free trial period:',
  claimPricingCol1: 'Package',
  claimPricingCol2: 'Booking system',
  claimPricingCol3: 'Booking & Management',
  claimSuccess:
    'Nicely done! Click below to set up your admin password and log in to your admin account. We also emailed you your admin account name.',
  claimSuccessCTA: 'CONTINUE TO YOUR SHOP',
  price11: '£20',
  price12: '£30',
  price21: '£30',
  price22: '£40',
  price31: '£40',
  price32: '£50',
  price41: '£50',
  price42: '£60',
  price51: '£60',
  price52: '£70',
  onboardingShopDialogTitle:
    'Please complete and review your business details.',
  onboardingShopDialogOwnerName:
    'Shop owner full name (individual or sole trader or company)',
  onboardingShopDialogCompanyNumber: 'Company number (if company)',
  onboardingShopDialogAddress: 'Address:',
  onboardingShopDialogAddressLine1: 'Address line 1',
  onboardingShopDialogAddressLine2: 'Address line 2',
  onboardingShopDialogAddressCity: 'City',
  onboardingShopDialogAddressPostcode: 'Postcode',
  onboardingShopDialogAddressCountry: 'Country',
  onboardingSuccessDialogTitle: 'Onboarding complete!',
  onboardingSuccessDialogp1:
    "Your customer booking website will take 5-10 minutes to be available. While you wait, set up your Staff Schedules and check the other menu options to fine-tune and brand your booking site. Every page has a '?' next to its title - check it out if you feel lost.",
  onboardingSuccessDialogp2:
    "We also emailed you your secondary account name (we call it 'shop account'). Set it up for daily usage for your team (it is a non admin account).",
  onboardingSuccessCTA: 'Go to staff schedules',
  onboardingOpeningHourDialogTitle: 'Set up your opening hours',
  onboardingOpeningHourDialogHelper:
    'Set up your opening hours, unpick any day when you are closed.',
  onboardingBarberDialogTitle: 'Set up your Team',
  onboardingBarberDialogHelper:
    'These are the names your guests will see when booking appointments.',
  onboardingBarberDialogTeamMember: 'Team Member',
  onboardingAddMore: 'Add more',
  onboardingServiceDialogTitle: 'Set up your Services',
  onboardingServiceDialogHelper:
    'These are the services your customers can book.',
  onboardingServiceDialogServiceCol: 'Service',
  onboardingServiceDialogPriceCol: 'Price of service',
  onboardingServiceDialogDurationCol: 'Duration of service',
  onboardingServiceDialogDuplicateError: 'Error: Duplicate service',
  onboardingServiceDialogNumberError: 'A valid number is required',
  onboardingDialogTitle: 'Onboarding - Takes about 10 mins to finish',
  onboardingDialogStep1Title: 'Team',
  onboardingDialogStep2Title: 'Services',
  onboardingDialogStep3Title: 'Opening hours',
  onboardingDialogStep4Title: 'Shop details',
  onboardingDialogStep5Title: 'Privacy Policy',
  onboardingDialogStep1:
    'Set up your team - you can always edit or add more people later.',
  onboardingDialogStep2:
    'Set up your services, prices and durations - you can add, edit and customise these later.\n \n In case you have different service pricing based on team members or experience, you can set it up later in the Admin Setup/Settings menu.',
  onboardingDialogStep3:
    'Set up your opening hours for every day of the week - you can always edit these later.',
  onboardingDialogStep4:
    'Review & add your shop details to make sure everything is accurate.',
  onboardingDialogStep5:
    'We got you covered for data protection and automatically create your tailor-made Privacy Policy for your booking site. Still, the legal responsibility sits with you - you can review and change the policy later if you want to.',
  onboardingDialogStepUnknown: 'Unknown step',
  onboardingDialogNext: 'Next',
  onboardingPolicyTitle: 'Create your Privacy Policy',
  onboardingPolicyBody:
    'You only have to add the time and city of creation for now, your Privacy Policy will be generated automatically and you can review and edit it later if you wish to.',
  onboardingPolicyDisclaimer:
    'It is the responsibility of the company to ensure that the Privacy Policy is fit for purpose and legally correct.',
  claimSetAdminPassword: 'Set up your password for your admin account',
  servicePriceExact: 'Exact price',
  miscSaleAmount: 'Extra Charge',
  extraSreviceBefore: 'Before {title}',
  extraSreviceAfter: 'After {title}',
  claimInvalidShop:
    'This account has already been claimed. If you have any questions, contact us at info@barbercloud.net, or login to your account now.',
  countryCzechRepublic: 'Česko',
  countryEngland: 'England',
  countryHungary: 'Magyarország',
  countryNorthernIreland: 'Northern Ireland',
  countryScotland: 'Scotland',
  countrySlovakia: 'Slovensko',
  countryWales: 'Wales',
  onboardingShopDialogDialogTitle: 'Add shop details',
  errorDuplicateTeamMember: 'Error: Duplicate team member',
  errorWebsiteStillBuilding:
    'We are building your booking page, please check back in 5-10 minutes.',
  tooltipAdminProductUsage:
    "Here you can find all the product use transactions for a given time period. You can review the details of each transaction or void them by clicking on the '...' in Details column.",
  tooltipAdminTransactions:
    "Here you can find all the sales and other cash flow transactions for a given time period. You can review the details of each transaction or void them by clicking on the '...' in Details column.",
  tooltipCloseTill:
    'You can have a quick overview of all your daily cash balance and can do a cash up - advised to be done every day. In case you do a new cash up on the same day, it will overwrite your previous cash up for that day.',
  tooltipProductUse:
    'You can track the products you use for providing services. This will be deducted from your inventory as well. Submitted transactions can be voided in the Product Usage menu under Reports.\r',
  tooltipMiscTransactions:
    'You can submit other cash flows here, like coffee, cleaning, etc. Cash items will impact your daily balance in the till for cash up.',
  tooltipInputPurchase:
    'You can enter your sales here which will feed your reports. You can void transaction in the Transactions tab, under Reports.',
  allFieldsRequiredErrorMessage: 'All fields marked with * are mandatory.',
  purchaseErrorPaymentMethodMissing: 'Payment method has to be selected!',
  bookingCustomerNotes: 'Customer notes',
};
